import * as React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ location, title, children }) => {
  //const rootPath = `${__PATH_PREFIX__}/`;
  //const isRootPath = location.pathname === rootPath;

  return (
    <div>
      <Header title={title} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
