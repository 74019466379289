import React from "react";
import { Link } from "gatsby";

const footer = (props) => {
  return (
    <footer className="footer py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 text-lg-start">
            Copyright © CrewCode Solutions 2022
          </div>
          <div className="col-lg-4 my-3 my-lg-0">
            {/*<a
              className="btn btn-dark btn-social mx-2"
              href="/"
              rel="noreferrer"
              target="_blank"
              aria-label="Stack Overflow"
            >
              <i className="fab fa-stack-overflow" />
  </a>*/}
            <a
              className="btn btn-dark btn-social mx-2"
              href="https://www.youtube.com/channel/UCgON7jg9LfWpALA263rhLyg/about"
              rel="noreferrer"
              target="_blank"
              aria-label="Youtube"
            >
              <i className="fab fa-youtube" />
            </a>
            {/*<a
              className="btn btn-dark btn-social mx-2"
              href="/"
              rel="noreferrer"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin-in" />
  </a>*/}
          </div>
          <div className="col-lg-4 text-lg-end">
            <Link
              className="link-dark text-decoration-none me-3"
              to="/privacypolicy"
            >
              Privacy Policy
            </Link>
            <Link
              className="link-dark text-decoration-none"
              to="/termsandcondition"
            >
              Terms of Use
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

footer.propTypes = {};

export default footer;
